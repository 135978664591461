import { useState, useEffect, React } from "react";
import { useMediaQuery } from "react-responsive";

import { useDispatch, useSelector } from "react-redux";
import { loadCategories } from "state/filters";
import { loadBrands } from "state/filters";
import { updateCategoriesSelected } from "state/filters";
import { updateBrandsSelected } from "state/filters";
import { updateRangePrice } from "state/filters";
import { setResetStatus } from "state/filters";

import { loadMore } from "state/products";
import { searchProducts } from "state/products";
import { loadRangePrice } from "state/products";
import { setDisplayPage } from "state/products";
import { resetProducts } from "state/products";

import InfiniteScroll from "../../SimpleEcommercePage/InfiniteScroll/InfiniteScroll";
import CircularIndeterminate from "../../SimpleEcommercePage/CircularIndeterminate";

// nodejs library that concatenates classes
import classNames from "classnames";
// plugin that creates slider
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui icons

import Cached from "@material-ui/icons/Cached";
import Check from "@material-ui/icons/Check";
// core components
import Accordion from "components/Accordion/Accordion.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Clearfix from "components/Clearfix/Clearfix.js";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.js";
import Products from "views/SimpleEcommercePage/Products";
import SearchInput from "views/SimpleEcommercePage/SearchInput";
import RangeSlider from "views/SimpleEcommercePage/SliderPrice";
import { loadProducts } from "state/products";

const useStyles = makeStyles(styles);

export default function SectionProducts() {
  // const [checked, setChecked] = useState([1, 9, 27]);
  const filters = useSelector((state) => state.filters);
  const products = useSelector((state) => state.products);

  const [checkedcat, setCheckedCat] = useState(filters.selectedCategories);
  const [checkedBrands, setCheckedBrands] = useState(filters.selectedBrands);
  const dispatch = useDispatch();

  const isTabletFloor = useMediaQuery({ query: "(max-width: 767px)" });
  var active = [0, 1, 2];
  if (isTabletFloor) active = [];

  useEffect(() => {
    console.log("SectionProductsCustom - useEffect");
    dispatch(loadRangePrice());
    dispatch(loadCategories());
    dispatch(loadBrands());
    dispatch(setDisplayPage({ displayPage: 1 }));
  }, [dispatch]);

  /*   useEffect(() => {
    console.log("setDisplayPage - useEffect");

    dispatch(setDisplayPage({ displayPage: 1 }));
  }, [dispatch]);
 */
  const handleToggleCategories = (value) => {
    const currentIndex = checkedcat.indexOf(value);
    const newChecked = [...checkedcat];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedCat(newChecked);
    dispatch(updateCategoriesSelected({ catSelected: newChecked }));
    dispatch(searchProducts(""));
    dispatch(
      setResetStatus({
        status: false,
      })
    );
  };

  const handleToggleBrands = (value) => {
    const currentIndex = checkedBrands.indexOf(value);
    const newChecked = [...checkedBrands];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedBrands(newChecked);
    console.log(newChecked);
    dispatch(updateBrandsSelected({ bSelected: newChecked }));
    dispatch(searchProducts(""));
    dispatch(
      setResetStatus({
        status: false,
      })
    );
  };

  const handleLoadMore = () => {
    let page = products.displayPage;

    const newChecked = [...checkedcat];

    if (newChecked.length > 0) dispatch(searchProducts(""));
    else {
      console.log("handleLoadMore");
      dispatch(setDisplayPage({ displayPage: page + 1 }));

      dispatch(loadMore());
    }
  };

  const resetFilters = () => {
    const newChecked = [];
    setCheckedBrands(newChecked);
    setCheckedCat(newChecked);
    dispatch(
      setResetStatus({
        status: true,
      })
    );

    dispatch(updateBrandsSelected({ bSelected: newChecked }));
    dispatch(updateCategoriesSelected({ catSelected: newChecked }));
    dispatch(resetProducts());
    dispatch(
      updateRangePrice({
        lowerPrice: products.lowerPrice,
        biggestPrice: products.higherPrice,
      })
    );
    dispatch(loadProducts());
  };

  const classes = useStyles();

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        {/* <h2>{products.displayPage}</h2> */}

        <GridContainer>
          <GridItem md={3} sm={3}>
            <Card plain>
              <CardBody className={classes.cardBodyRefine}>
                <h4 className={classes.cardTitle + " " + classes.textLeft}>
                  Repor
                  <Tooltip
                    id="tooltip-top"
                    title="Repor filtros"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      link
                      justIcon
                      size="sm"
                      onClick={resetFilters}
                      className={classes.pullRight + " " + classes.refineButton}
                    >
                      <Cached />
                    </Button>
                  </Tooltip>
                  <Clearfix />
                </h4>
                <Accordion
                  active={active}
                  activeColor="secondary"
                  collapses={[
                    {
                      title: "Preço",
                      content: <RangeSlider />,
                    },
                    {
                      title: "Categorias",
                      content: (
                        <div className={classes.customExpandPanel}>
                          <div
                            className={
                              classes.checkboxAndRadio +
                              " " +
                              classes.checkboxAndRadioHorizontal
                            }
                          >
                            {filters.categories.data &&
                            filters.loading === false
                              ? filters.categories.data.map(function (cat) {
                                  return (
                                    <FormControlLabel
                                      key={cat.id}
                                      control={
                                        <Checkbox
                                          disableRipple
                                          tabIndex={-1}
                                          onClick={() =>
                                            handleToggleCategories(cat.id)
                                          }
                                          checked={
                                            checkedcat.indexOf(cat.id) !== -1
                                              ? true
                                              : false
                                          }
                                          checkedIcon={
                                            <Check
                                              className={classes.checkedIcon}
                                            />
                                          }
                                          icon={
                                            <Check
                                              className={classes.uncheckedIcon}
                                            />
                                          }
                                          classes={{
                                            checked: classes.checked,
                                            root: classes.checkRoot,
                                          }}
                                        />
                                      }
                                      classes={{ label: classes.label }}
                                      label={cat.name.pt}
                                    />
                                  );
                                })
                              : "Loading..."}
                          </div>
                        </div>
                      ),
                    },
                    {
                      title: "Marca",
                      content: (
                        <div className={classes.customExpandPanel}>
                          <div
                            className={
                              classes.checkboxAndRadio +
                              " " +
                              classes.checkboxAndRadioHorizontal
                            }
                          >
                            {filters.brands.data && filters.loading === false
                              ? filters.brands.data.map(function (brand) {
                                  return (
                                    <FormControlLabel
                                      key={brand.id}
                                      control={
                                        <Checkbox
                                          disableRipple
                                          tabIndex={-1}
                                          onClick={() =>
                                            handleToggleBrands(brand.id)
                                          }
                                          checked={
                                            checkedBrands.indexOf(brand.id) !==
                                            -1
                                              ? true
                                              : false
                                          }
                                          checkedIcon={
                                            <Check
                                              className={classes.checkedIcon}
                                            />
                                          }
                                          icon={
                                            <Check
                                              className={classes.uncheckedIcon}
                                            />
                                          }
                                          classes={{
                                            checked: classes.checked,
                                            root: classes.checkRoot,
                                          }}
                                        />
                                      }
                                      classes={{ label: classes.label }}
                                      label={brand.name.pt}
                                    />
                                  );
                                })
                              : "Loading..."}
                          </div>
                        </div>
                      ),
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem md={9} sm={9}>
            <SearchInput b={10} />

            <Products />

            {products.loading ? <CircularIndeterminate /> : ""}

            {filters.selectedCategories.length == 0 &&
              products.list.length > 0 &&
              !products.loading &&
              products.displayPage < products.lastPage && (
                <InfiniteScroll fetchMore={() => handleLoadMore()} />
              )}
            {filters.selectedCategories.length == 0 &&
              products.list.length > 0 &&
              !products.loading &&
              products.displayPage < products.lastPage && <h1>SIM!!!</h1>}
            <GridItem
              md={3}
              sm={3}
              className={classNames(classes.mlAuto, classes.mrAuto)}
            ></GridItem>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
